import { createBrowserRouter } from 'react-router-dom';

import { Error as ErrorComponent } from './pages/Error';
import { Checkout } from './pages/Checkout';
import { PaymentSuccess } from './pages/PaymentSuccess';
import { Index } from './pages/Index';

import { defaultTitle, error404 } from './constants';

import { getErrorObj } from './utilities/getErrorObj';

const error = getErrorObj(error404, defaultTitle);

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    errorElement: <ErrorComponent errorProp={error} />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/success',
    element: <PaymentSuccess />,
  },
  {
    path: '/error',
    element: <ErrorComponent />,
  },
]);
