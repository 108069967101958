import { useState, useEffect } from 'react';

import { getErrorObj } from '../utilities/getErrorObj';

import {
  error404,
  error503_504,
  defaultTitle,
  serverError,
} from '../constants';

export const useFetch = (url, options) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const errorObj = getErrorObj(error404, defaultTitle);

  useEffect(() => {
    setLoading(true);
    fetch(url, {
      ...options,
      headers: new Headers({
        'X-Api-Key': `${process.env.REACT_APP_X_API_KEY}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 503 || response.status === 504) {
            errorObj.message = error503_504;
            errorObj.statusCode = serverError;
          }
          throw errorObj;
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [url]);

  return [data, loading, error];
};
