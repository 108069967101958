/**
 * @param string
 * @returns [childId, guardianId]
 *
 */
export const decodeBase64 = (encoded) => {
  if (!encoded | (typeof encoded !== 'string')) return [];

  const decoded = atob(encoded);

  return decoded.split('|');
};

/**
 * @param string
 * @returns decoded childId
 *
 */
const decodeChildId = (encoded) => {
  if (!encoded | (typeof encoded !== 'string')) return '';

  const decoded = atob(encoded);

  return decoded.split('|')[0];
};

/**
 * @param string
 * @returns encoded childId
 *
 */
export const encodeChildId = (encoded) => {
  const childId = decodeChildId(encoded);

  return btoa(childId);
};
