import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useLocation, useNavigate } from 'react-router-dom';

import { CheckoutForm } from '../components/CheckoutForm';
import { Header } from '../components/Header';
import { Price } from '../components/Price';
import { Information } from '../components/Information';
import { PhotoGrid } from '../components/PhotoGrid';
import { Spinner } from '../components/Spinner';

import { useFetch } from '../hooks/useFetch';

import {
  checkoutText,
  checkoutPriceText,
  checkoutStripeURL,
} from '../constants/constants';

import './checkout.scss';

//Call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHABLE_KEY}`);

export const Checkout = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { childId, guardianId, guardianEmail, imageUrls, encodedParam, archiveAmount, archiveExpired } = state;

  const [data, loading, error] = useFetch(
    `${process.env.REACT_APP_BASE_URL}/payment-intents`,
    {
      method: 'POST',
      body: JSON.stringify({
        childId,
        guardianId,
        guardianEmail,
        archiveExpired
      }),
    }
  );

  if (error) {
    navigate('/error', { state: { encodedParam, error } });
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret: data?.client_secret,
    appearance,
  };

  return (
    <main className='checkout'>
      <section className='checkout__photo-grid'>
        <PhotoGrid imageUrls={imageUrls} />
      </section>
      <section className='checkout__payment'>
        <div className='checkout__payment__wrapper'>
          <div className='checkout__info'>
            <Header />
            <Information title={checkoutText} />
            <Price disclaimer={checkoutPriceText} 
              amount={(parseInt(archiveAmount, 10) / 100).toString()} />
          </div>
          {!loading ? (
            <div className='checkout__message'>
              {data?.client_secret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm encodedParam={encodedParam} />
                </Elements>
              )}
            </div>
          ) : (
            <Spinner isLoading={loading} />
          )}
          <div className='checkout__stripe'>
            <a href={`${checkoutStripeURL}`} target='_blank' rel='noreferrer'>
              Powered by <span>Stripe</span>
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};
