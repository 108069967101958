import './button.scss';

export const Button = ({
  size = 'small',
  type = 'primary',
  value,
  disabled,
  onClick,
}) => {
  const handleClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };
  return (
    <button
      className={`button button--${size} button--${type}`}
      aria-disabled={disabled}
      onClick={handleClick}
    >
      {value}
    </button>
  );
};
