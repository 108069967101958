/**
 * @param string
 * @param string
 * @returns {statusCode: string, message: string}
 *
 */
export const getErrorObj = (statusCode, message) => {
  if (!statusCode || !message) return {};

  let error = new Error(message);
  error.statusCode = statusCode;

  return error;
};
