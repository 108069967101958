import { useSearchParams, useNavigate } from 'react-router-dom';

import { Header } from '../components/Header';
import { Price } from '../components/Price';
import { Information } from '../components/Information';
import { PhotoGrid } from '../components/PhotoGrid';
import { Spinner } from '../components/Spinner';

import { useFetch } from '../hooks/useFetch';

import { dateFormat } from '../utilities/dateFormat';

import {
  successTitle,
  successLink,
  successDisclaimer,
  successDisclaimerNoDate,
  priceWarning,
  infoFaqLink,
  infoFaqLinkDescription,
} from '../constants/constants';

import './payment-success.scss';

export const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const encodedParam = searchParams.get('id');
  const navigate = useNavigate();

  const [data, loading, error] = useFetch(
    `${process.env.REACT_APP_BASE_URL}/archives?id=${encodedParam}`,
    { method: 'GET' }
  );

  if (error) {
    navigate('/error', { state: { encodedParam, error } });
  }

  const { downloadExpiry, imageUrls = [] } = data || {};

  return (
    <div className='payment-success'>
      {!loading ? (
        <>
          <section className='payment-success__photo-grid'>
            <PhotoGrid imageUrls={imageUrls} />
          </section>
          <section className='payment-success__content'>
            <div className='payment-success__content__wrapper'>
              <Header />
              <Information title={successTitle} link={successLink} />
              <Price
                disclaimer={
                  downloadExpiry ? successDisclaimer : successDisclaimerNoDate
                }
                date={downloadExpiry ? dateFormat(downloadExpiry) : ''}
                warning={priceWarning}
                infoFaq={{
                  link: infoFaqLink,
                  description: infoFaqLinkDescription,
                }}
              />
            </div>
          </section>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
