import './information.scss';

export const Information = ({
  title = '',
  subtitle = '',
  description = '',
  info = '',
  link = '',
}) => {
  return (
    <div className='information'>
      <h3 className='information__title'>{title}</h3>
      {subtitle && <h4>{subtitle}</h4>}
      {description && (
        <p
          className='information__description'
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      )}
      {info && <p dangerouslySetInnerHTML={{ __html: info }}></p>}
      {link && (
        <p
          className='information__link'
          dangerouslySetInnerHTML={{ __html: link }}
        ></p>
      )}
    </div>
  );
};
