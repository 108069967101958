import { useNavigate } from 'react-router-dom';

import { Header } from '../components/Header';
import { Price } from '../components/Price';
import { Information } from '../components/Information';
import { PhotoGrid } from '../components/PhotoGrid';

import { dateFormat } from '../utilities/dateFormat';

import { ReactComponent as ExpiredIcon } from '../assets/icons/expired-icon.svg';

import { encodeChildId } from '../utilities/decodeBase64';

import {
  available,
  pending,
  expired,
  availableTitle,
  availableSubtitle,
  availableDescription,
  priceWarning,
  successDisclaimer,
  successDisclaimerNoDate,
  availableBtnText,
  pendingTitle,
  pendingSubtitle,
  pendingDescription,
  expiredTitle,
  expiredSubtitle,
  expiredDescription,
  expiredText,
  infoFaqLink,
  infoFaqLinkDescription,
  getSupportEmail
} from '../constants/constants';

import './download.scss';

export const Download = ({ data, encodedParam }) => {
  const {
    downloadExpiry,
    archiveStatus,
    childFirstName,
    schoolName,
    imageUrls = [],
  } = data || {};

  const navigate = useNavigate();

  const childId = encodeChildId(encodedParam);

  const handleDownload = () => {
    try {
      window.open(`${process.env.REACT_APP_BASE_URL}/downloads/${childId}`);
    } catch (error) {
      navigate('/error', { state: { encodedParam } });
    }
  };

  return (
    <div className='download'>
      <section className='download__photo-grid'>
        {archiveStatus === expired ? (
          <div className='download__expired'>
            <div>
              <ExpiredIcon />
            </div>
          </div>
        ) : (
          <PhotoGrid imageUrls={imageUrls} />
        )}
      </section>
      <section className='download__content'>
        <div className='download__content__wrapper'>
          <Header />
          {/* download available */}
          {archiveStatus === available && (
            <>
              <Information
                title={availableTitle}
                subtitle={`${availableSubtitle} ${childFirstName}${availableDescription}`}
                description={
                  '<strong>Please note:</strong> You will need to open this link on a desktop or laptop computer.'
                }
              />
              <Price
                disclaimer={successDisclaimer}
                warning={priceWarning}
                date={downloadExpiry ? dateFormat(downloadExpiry) : ''}
                btnText={availableBtnText}
                onClick={handleDownload}
                btnType='secondary'
                infoFaq={{
                  link: infoFaqLink,
                  description: infoFaqLinkDescription,
                }}
              />
            </>
          )}
          {/* download pending */}
          {archiveStatus === pending && (
            <>
              <Information
                title={pendingTitle}
                subtitle={`${pendingSubtitle} ${childFirstName}${pendingDescription}`}
                description={
                  'You will receive a download link by email in the next 24 hours.'
                }
              />
              <Price
                disclaimer={successDisclaimerNoDate}
                warning={priceWarning}
                date={''}
                infoFaq={{
                  link: infoFaqLink,
                  description: infoFaqLinkDescription,
                }}
              />
            </>
          )}
          {/* download expired */}
          {archiveStatus === expired && (
            <>
              <Information
                title={expiredTitle}
                subtitle={`${expiredSubtitle} ${childFirstName}${expiredDescription} ${schoolName} ${expiredText}`}
              />
              <Price
                infoFaq={{
                  link: infoFaqLink,
                  description: infoFaqLinkDescription,
                }}
              />
            </>
          )}
          <p className='download__contact'>
            For help contact Educa at{' '}
            <a href={`mailto:${getSupportEmail(window.location.hostname)}`}>{getSupportEmail(window.location.hostname)}</a>
          </p>
        </div>
      </section>
    </div>
  );
};
