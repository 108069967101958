import { useNavigate } from 'react-router-dom';

import { PhotoGrid } from '../components/PhotoGrid';
import { Header } from '../components/Header';
import { MediaDescription } from '../components/MediaDescription';
import { Information } from '../components/Information';
import { Price } from '../components/Price';

import { dateFormat } from '../utilities/dateFormat';

import {
  infoTitle,
  infoSubtitle,
  mediaTitle,
  priceExpires,
  priceOfferExpire,
  priceBtnText,
  priceWarning,
  getInfoDescription,
  getExpiredInfoDescription,
  getInfoPriceText,
  getInfoText,
  getExpiredInfoText,
  infoFaqLink,
  infoFaqLinkDescription,
} from '../constants/constants';

import './home.scss';

export const Home = ({ data, childId, guardianId, encodedParam }) => {
  const navigate = useNavigate();

  const {
    schoolName,
    storiesAvailable,
    groupStoriesAvailable,
    groupPhotosAvailable,
    groupVideosAvailable,
    individualStoriesAvailable,
    individualVideosAvailable,
    individualPhotosAvailable,
    photosAvailable,
    videosAvailable,
    imageUrls = [],
    childFirstName,
    guardianEmail = '',
    downloadExpiry,
    archiveAmount,
    archiveExpired
  } = data || {};

  return (
    <main className='main'>
      <section className='main__photo-grid'>
        <PhotoGrid imageUrls={imageUrls} />
      </section>
      <section className='main__content'>
        <div className='main__content__wrapper'>
          <Header />
          <Information
            title={infoTitle}
            subtitle={infoSubtitle}
            description={ archiveExpired ? getExpiredInfoDescription(childFirstName, schoolName) : getInfoDescription(childFirstName, schoolName) }
            info={archiveExpired ? getExpiredInfoText(childFirstName) : getInfoText(childFirstName)}
            link={getInfoPriceText(archiveAmount)}
          />
          <MediaDescription
            title={mediaTitle}
            storiesAvailable={storiesAvailable}
            groupStoriesAvailable={groupStoriesAvailable}
            groupPhotosAvailable={groupPhotosAvailable}
            groupVideosAvailable={groupVideosAvailable}
            individualStoriesAvailable={individualStoriesAvailable}
            individualPhotosAvailable={individualPhotosAvailable}
            individualVideosAvailable={individualVideosAvailable}
            photosAvailable={photosAvailable}
            videosAvailable={videosAvailable}
          />
          <Price
            disclaimer={priceOfferExpire}
            date={downloadExpiry ? dateFormat(downloadExpiry) : priceExpires}
            btnText={priceBtnText}
            warning={priceWarning}
            infoFaq={{ link: infoFaqLink, description: infoFaqLinkDescription }}
            onClick={() =>
              navigate('/checkout', {
                state: {
                  childId,
                  guardianId,
                  guardianEmail,
                  imageUrls,
                  encodedParam,
                  archiveAmount,
                  archiveExpired
                },
              })
            }
          />
        </div>
      </section>
    </main>
  );
};
