// Information component
export const getExpiredInfoDescription = (childFirstName, schoolName) => {
  return `The previous special offer to purchase these memories for $19.99 has expired as we have removed the files from our database and started deleting them. <br /><br />You're in luck though. <strong>${childFirstName}'s</strong> memories from <strong>${schoolName}</strong> are still available and can be restored.`;
  // return null;
};

export const getExpiredInfoText = (childFirstName) => {
  return `Should you wish us to restore them, in order for you to download the memories, don't wait any longer.`;
  // return `The magic of Christmas is in the air! We've unlocked our Christmas Vault one last time, revealing an exclusive holiday offer to grab <strong>${childFirstName}'s</strong> memories.`;
};

// Information component
export const getInfoDescription = (childFirstName, schoolName) => {
  //return `We're doing some spring cleaning and are about to move <strong>${childFirstName}'s</strong> learning stories from their time at <strong>${schoolName}</strong> out of our system <strong>${infoDate}</strong>.`;
  return `We're doing some spring cleaning and are about to move <strong>${childFirstName}'s</strong> learning stories from their time at <strong>${schoolName}</strong> out of our system.`;
};

export const getInfoText = (childFirstName) => {
  // return `On that date all of <strong>${childFirstName}'s</strong> photos, videos and other memories will be permanently deleted.`;
  return `Once that happens, all of <strong>${childFirstName}'s</strong> photos, videos and other memories will be permanently deleted.`;
};

export const getInfoPriceText = (amount) => 
  `For <strong>$${(parseInt(`${amount}`, 10) / 100).toString()}</strong> you will get:`;

export const getSupportEmail = (hostName) => {
  const currentDomain = hostName.substring(hostName.indexOf(".", hostName.indexOf(".") - 1) + 1);
  return `archivesupport@${currentDomain}`;
};

export const infoDate = `at the end of next month`;
export const infoTitle = `Don't let these treasured memories disappear forever!`;
export const infoSubtitle = '';
export const infoLink = 'Learn more about the Educa memory recovery';
export const infoFaqLink = 'https://www.geteduca.com/archiving-faq/';
export const infoFaqLinkDescription =
  'Read our FAQ to learn more about Educa Archiving';

//MediaDescription component
export const mediaTitle = `What's included`;
export const mediaIndividualStories = 'Individual Stories';
export const mediaGroupStories = 'Group Stories';
export const mediaLearningStories = 'Learning Stories';

// Price component
//export const priceAmount = (parseInt(`${process.env.REACT_APP_ARCHIVE_CHARGE_AMOUNT}`, 10) / 100).toString();
export const priceExpires = '1 month from now';
export const priceOfferExpire = 'Offer expires';
export const priceDownloadExpire = 'Download expires';
export const priceBtnText = 'Buy Now';
export const priceWarning = 'All content will be permanently deleted';

// Error page
export const errorTitle = 'Oops!';
export const errorMessage = 'Sorry, an unexpected error has ocurred.';
export const errorAction = 'Please try again later.';
export const serverError = '503 Server Error';
export const error503_504 = `We are experiencing a high volume of activity. 
Please refresh or click try again.`;
export const error404 = `404 Page not found`;

// Default page
export const defaultTitle =
  'Please follow the link in your email and try again.';

// Checkout page
export const checkoutText = 'Pay now to get your copy';
export const checkoutPriceText =
  'Price is listed in your local currency and includes GST.';
export const checkoutStripeURL = 'https://stripe.com/legal/spc';

// Success page
export const successTitle = 'Payment success. Yay!';
export const successLink =
  "We'll go away and gather up all the stories, images and videos we can find. <br>This can take anywhere from a few minutes, to a few hours but don't worry! <br>We'll email you with a download link as soon as it's ready.";
export const successDisclaimer = 'Your download link expires on';
export const successDisclaimerNoDate =
  'Your download link will expire in one month';

// archiveStatus
export const initial = 'U';

export const available = 'A';
export const availableTitle = 'Portfolio ready to download';
export const availableSubtitle = `We're done picking out`;
export const availableDescription = `'s personal stories. Enjoy the memories!`;
export const availableBtnText = 'Download now';

export const pending = 'P';
export const pendingTitle = 'Download processing';
export const pendingSubtitle = `We're just picking out`;
export const pendingDescription = `'s personal stories. This can take a few minutes or a few hours.`;

export const expired = 'E';
export const expiredTitle = 'Archive expired';
export const expiredSubtitle = `All content hosted by Educa relating to`;
export const expiredDescription = `'s time at`;
export const expiredText = `has been permanently deleted.`;
