import { Button } from './Button';

import './price.scss';

export const Price = ({
  amount = '',
  disclaimer = '',
  date = '',
  currencySymbol = '$',
  warning = '',
  btnText = '',
  onClick = () => {},
  btnType = 'primary',
  infoFaq = { link: '', description: '' },
}) => {
  return (
    <div className='price'>
      {amount && (
        <span className='price__amount'>{`${currencySymbol}${amount}`}</span>
      )}
      {btnText && (
        <Button size='small' value={btnText} onClick={onClick} type={btnType} />
      )}
      {infoFaq && (
        <a
          className='price__fqa-link'
          href={infoFaq.link}
          target='_blank'
          rel='noreferrer'
        >
          {infoFaq.description}
        </a>
      )}
    </div>
  );
};
