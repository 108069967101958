import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/icons/educa-logo.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/error-icon.svg';
import { Button } from '../components/Button';

import { errorTitle, errorMessage, getSupportEmail } from '../constants';

import './error.scss';

// I could get the error from url (error) or from props (errorProp)
// I need to check both of them
export const Error = ({ errorProp }) => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { encodedParam, error } = state || {};

  const handleClick = () => {
    navigate(`/?id=${encodedParam}`);
  };

  return (
    <div className='error'>
      <div className='error__message'>
        <div className='error__logo'>
          <Logo />
        </div>
        <div className='error__title'>
          <ErrorIcon />
          {errorProp && (
            <h3>{errorProp ? errorProp.statusCode : errorTitle}</h3>
          )}
          {error && <h3>{error ? error.statusCode : errorTitle}</h3>}
        </div>

        {errorProp && <h5>{errorProp ? errorProp.message : errorMessage}</h5>}
        {error && <h5>{error ? error.message : errorMessage}</h5>}
        {encodedParam && (
          <Button
            size='medium'
            value='Try again'
            onClick={handleClick}
            type='primary'
          />
        )}
        <p className='error__contact'>
          For help contact Educa at{' '}
          <a href={`mailto:${getSupportEmail(window.location.hostname)}`}>{getSupportEmail(window.location.hostname)}</a>
        </p>
      </div>
    </div>
  );
};
