import ClipLoader from 'react-spinners/ClipLoader';

export const Spinner = ({
  color = '#FF8B47',
  isLoading,
  ccsOverride = {},
  size = 50,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ClipLoader
        color={color}
        loading={isLoading}
        cssOverride={ccsOverride}
        size={size}
        aria-label='Loading Spinner'
        data-testid='loader'
      />
    </div>
  );
};
