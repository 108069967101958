import { ReactComponent as StoryIcon } from '../assets/icons/story-icon.svg';
import { ReactComponent as PhotoIcon } from '../assets/icons/photo-icon.svg';
import { ReactComponent as VideoIcon } from '../assets/icons/video-icon.svg';

import {
  mediaGroupStories,
  mediaIndividualStories,
  mediaLearningStories,
} from '../constants';

import './media-description.scss';

export const MediaDescription = ({
  title = '',
  storiesAvailable,
  photosAvailable,
  videosAvailable,
  groupStoriesAvailable,
  groupPhotosAvailable,
  groupVideosAvailable,
  individualStoriesAvailable,
  individualPhotosAvailable,
  individualVideosAvailable,
}) => {
  return (
    <div className='media-description'>
      <h4 className='media-description__title'>{title}</h4>
      <ul className='media-description__list'>
        {/* if either individualStoriesAvailable  or groupStoriesAvailable has a value, then use those, otherise use storiesAvailable */}
        {individualStoriesAvailable || groupStoriesAvailable ? (
          <>
            {individualStoriesAvailable ? (
              <li className='media-description__list__item'>
                <StoryIcon />{' '}
                <div>
                  <span className='media-description__list__item__category'>{`${individualStoriesAvailable} ${mediaIndividualStories}`}</span>
                  <div className='media-description__list__item__count'>
                    {individualPhotosAvailable > 0 && (
                      <span>{`${individualPhotosAvailable} Photos`}</span>
                    )}
                    {individualVideosAvailable > 0 && (
                      <span>{`${individualVideosAvailable} Videos`}</span>
                    )}
                  </div>
                </div>
              </li>
            ) : null}
            {groupStoriesAvailable ? (
              <li className='media-description__list__item'>
                <StoryIcon />{' '}
                <div>
                  <span className='media-description__list__item__category'>{`${groupStoriesAvailable} ${mediaGroupStories}`}</span>
                  <div className='media-description__list__item__count'>
                    {groupPhotosAvailable > 0 && (
                      <span>{`${groupPhotosAvailable} Photos`}</span>
                    )}
                    {groupVideosAvailable > 0 && (
                      <span>{`${groupVideosAvailable} Videos`}</span>
                    )}
                  </div>
                </div>
              </li>
            ) : null}
          </>
        ) : (
          <>
            {storiesAvailable ? (
              <li className='media-description__list__item'>
                <StoryIcon />{' '}
                <span>{`${storiesAvailable} ${mediaLearningStories}`}</span>
              </li>
            ) : null}
            {photosAvailable ? (
              <li className='media-description__list__item'>
                <PhotoIcon /> <span>{`${photosAvailable} Photos`}</span>
              </li>
            ) : null}
            {videosAvailable ? (
              <li className='media-description__list__item'>
                <VideoIcon /> <span>{`${videosAvailable} Videos`}</span>
              </li>
            ) : null}
          </>
        )}
      </ul>
      <p className='media-description__annotation'>* Numbers may include some duplicates that will be filtered out</p>
    </div>
  );
};
