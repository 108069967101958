import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Home } from './Home';
import { Error } from './Error';
import { Download } from './Download';
import { useFetch } from '../hooks/useFetch';
import { Spinner } from '../components/Spinner';
import {
  available,
  error404,
  defaultTitle,
  expired,
  initial,
  pending,
} from '../constants';
import { decodeBase64 } from '../utilities/decodeBase64';
import { getErrorObj } from '../utilities/getErrorObj';

export const Index = () => {
  const [searchParams] = useSearchParams();
  const encodedParam = searchParams.get('id');

  const [childId, guardianId] = decodeBase64(encodedParam);

  const navigate = useNavigate();

  const [data, loading, error] = useFetch(
    `${process.env.REACT_APP_BASE_URL}/archives?id=${encodedParam}`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (data) {
      const {
        guardianFirstName,
        guardianLastName,
        childFirstName,
        childLastName,
        guardianEmail,
        schoolName,
        legacyS3FolderName,
        schoolId,
        downloadUrl,
        archiveStatus,
        groupStoriesAvailable,
        groupPhotosAvailable,
        groupVideosAvailable,
        individualStoriesAvailable,
        individualPhotosAvailable,
        individualVideosAvailable,
        intercomUserHash
      } = data;

      window.Intercom('shutdown');
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'fr10dtu4',
        user_hash: intercomUserHash,
        name: `${guardianFirstName} ${guardianLastName}`, // Guardian full name
        child_name: `${childFirstName} ${childLastName}`, // Child full name
        email: guardianEmail, // Guardian email,
        school: schoolName, // School name
        school_id: schoolId,
        school_url: `https://${legacyS3FolderName}`, // Use "https://" + value of "legacyS3FolderName"
        archive_url: window.location.href, // The current url in the web browser bar
        archive_download_url: downloadUrl, // The direct download url e.g. https://apigw.educa.co.nz/archive/v1/downloads/...
        archive_status: archiveStatus, // E.g. U, P or A
        archive_group_stories: groupStoriesAvailable, // Note: use numbers, not numbers as strings
        archive_group_photos: groupPhotosAvailable,
        archive_group_videos: groupVideosAvailable,
        archive_individual_stories: individualStoriesAvailable,
        archive_individual_photos: individualPhotosAvailable,
        archive_individual_videos: individualVideosAvailable,
      });
    }
  }, [data]);

  if (error) {
    navigate('/error', { state: { encodedParam, error } });
  }

  // Error object to be passed to Error component
  const err404 = getErrorObj(error404, defaultTitle);

  const { archiveStatus } = data || {};

  return (
    <>
      {!loading ? (
        <>
          {!encodedParam && <Error errorProp={err404} />}
          {archiveStatus === initial && (
            <Home
              data={data}
              childId={childId}
              guardianId={guardianId}
              encodedParam={encodedParam}
            />
          )}
          {(archiveStatus === pending ||
            archiveStatus === available ||
            archiveStatus === expired) && (
            <Download data={data} encodedParam={encodedParam} />
          )}
        </>
      ) : (
        <Spinner size={150} isLoading={loading} />
      )}
    </>
  );
};
