import './photo-grid.scss';

export const PhotoGrid = ({ imageUrls = [] }) => {
  return (
    <div className='photo-grid'>
      {imageUrls.map((img, index) => (
        <div
          className='photo-grid__image'
          key={`${index}-${img}`}
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      ))}
    </div>
  );
};
