const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

/**
 *
 * @param {date} string
 * @returns string
 *
 * Parse from 2022-10-15T13:02:12.000Z to 15 Oct 2022
 */
export const dateFormat = (date) => {
  if (!date) return '';

  const newDate = new Date(date);

  return `${newDate.getDate()} ${
    months[newDate.getMonth()]
  } ${newDate.getFullYear()}`;
};
