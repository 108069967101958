import { ReactComponent as Logo } from '../assets/icons/educa-logo.svg';

import './header.scss';

export const Header = () => {
  return (
    <div className='header'>
      <Logo className='header__logo' />
    </div>
  );
};
